import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { isMobile } from "react-device-detect";
import Fade from "react-reveal/Fade";
import { Link } from "react-scroll";
import { ScrollRotate } from "react-scroll-rotate";
import styles from "./index.module.css";

import Layout from "../components/layout";
import SEO from "../components/seo";

import growthSeeker from "../images/growth-seeker.svg";
import facebook from "../images/socials/facebook.svg";
import instagram from "../images/socials/instagram.svg";
import linkedin from "../images/socials/linkedin.svg";
import unsplash from "../images/socials/unsplash.svg";

const IndexPage = () => {
  const [isMobileCheck, setIsMobileCheck] = useState(isMobile);
  if (isMobileCheck) {
    return (
      <Layout>
        <SEO title="Lifelong Learner" />
        <div className="textCenter">
          Mobile-responsiveness is still a work in progress. Please use desktop
          for better viewing.
          <br />
          <button
            onClick={() => setIsMobileCheck(false)}
            className={`${styles.primaryButton} ${styles.marginTop20}`}
          >
            Continue to Site
          </button>
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <SEO title="Lifelong Learner" />
        <section>
          <Fade cascade>
            <div className="textCenter">
              <span className={styles.subText}>TALE OF A</span>
            </div>
            <div>
              <span className={`${styles.heroText} ${styles.aboveText}`}>
                lifelong
              </span>
              <span
                className={`${styles.heroText} ${styles.outline} ${styles.belowText}`}
              >
                learner
              </span>

              <div className={styles.heroDiv}>
                <div>
                  <StaticImage
                    src="../images/hersight.jpg"
                    alt="jes"
                    quality={100}
                  />
                </div>
              </div>
            </div>
          </Fade>
          <div className="section">
            <span className="hscroll-line"></span>
          </div>
        </section>
        <section>
          <Fade cascade>
            <div className={styles.row}>
              <div className={`${styles.column} ${styles.aboutImgDiv}`}>
                <StaticImage
                  alt="about jes"
                  quality={100}
                  src="../images/pudgy.png"
                />
              </div>
              <div className={styles.column}>
                <p>
                  Hi! I’m <strong>Jes</strong>.
                </p>
                <p>
                  I previously worked as a full-stack developer for a
                  medium-sized company but saw the opportunity in the Web3 space.
                  I decided to take my leap of faith to learn more and at the
                  same time, find my ikigai.
                </p>
                <p>
                  To navigate the space as a Web3 enthusiast, I have experienced being a mod in some
                  discord servers, designed and developed a few sites, and did
                  some degen plays with friends!
                </p>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <button
                    className={`${styles.primaryButton} ${styles.marginTop20}`}
                  >
                    Get in touch
                  </button>
                </Link>

                <div className={styles.growthSeeker}>
                  <ScrollRotate>
                    <img src={growthSeeker} width="100%" alt="growth seeker" />
                  </ScrollRotate>
                </div>
              </div>
            </div>
          </Fade>
        </section>
        <section>
          <Fade cascade>
            <div className={`${styles.sectionHeading} ${styles.textRight}`}>
              <span className={styles.marginRight100}>learning</span>
              <br />
              <span className={styles.outline}>by doing</span>
            </div>
            <div className={styles.row}>
              <div className={styles.column}>
                <div className={styles.servicesLeft}>
                  <h1>I do design</h1>
                  <p>
                    I create compelling graphic and web design work. 
                  </p>
                  <p>
                    Most of the design work I've done for the web3 space is mostly for me to get whitelist (lol). But in Web2, I used to help design social media posts for the organizations I work or volunteer for.
                  </p>
                  <p>
                    <em>Samples attached:</em> Discord banner for <a href="https://twitter.com/0xJes/status/1425153967802109958">Robotos</a> and a random project. <a href="https://www.figma.com/proto/6i1kpK4frqjPOVZJMaiRlU/PXN?page-id=0%3A1&node-id=2%3A39">Entry for PXN</a>'s web design contest.
                  </p>
                </div>
              </div>
              <div className={`${styles.column} ${styles.aboutImgDiv}`}>
                <StaticImage
                  alt="web design"
                  className={styles.sampleWork}
                  quality={100}
                  src="../images/design.png"
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={`${styles.column} ${styles.aboutImgDiv}`}>
                <StaticImage
                  alt="web development"
                  className={styles.sampleWork}
                  quality={100}
                  src="../images/development.png"
                />
              </div>
              <div className={styles.column}>
                <div className={styles.servicesRight}>
                  <h1>I do web development</h1>
                  <p>
                    In my previous company, I have worked on different web apps including different landing pages,  a fintech app, and an interior designer app.
                  </p>
                  <p>
                    Currently, I accept and do front-end development work for Web3 projects.
                  </p>
                  <p>
                    <em>Samples attached:</em> <a href="https://jes-kumo-3123sd.netlify.app/">Initial website</a> for Kumo x World (this is the test site link) and <a href="https://twitter.com/0xJes/status/1563150807469793289">personal project</a> for y00tslist.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.column}>
                <div className={styles.servicesLeft}>
                  <h1>I do modding</h1>
                  <p>
                    IRL, I help manage tech communities by helping organize local events.
                  </p>
                  <p>
                    In Web3, I was able to help mod for a few projects including <a href="https://twitter.com/0n1Force">0n1Force</a>, <a href="https://twitter.com/thelittlesnft">The Littles</a>, <a href="https://twitter.com/raidparty">Raid Party</a>, and <a href="https://opensea.io/collection/snif">SNIF</a>.
                  </p>
                </div>
              </div>
              <div className={`${styles.column} ${styles.aboutImgDiv}`}>
                <StaticImage
                  alt="about jes"
                  className={styles.sampleWork}
                  quality={100}
                  src="../images/modding.png"
                />
              </div>
            </div>
          </Fade>
        </section>
        <section id="contact" className={styles.fullWidth}>
          <div className={`textCenter ${styles.padding50}`}>
            <p>
              This site is a work in progress. Send me a message to see more
              samples of my work.
            </p>

            <a href="https://twitter.com/0xJes" target="_blank">
              <button
                className={`${styles.secondaryButton} ${styles.margin10}`}
              >
                DM Jes on Twitter
              </button>
            </a>
          </div>
        </section>
      </Layout>
    );
  }
};

export default IndexPage;
